import { format, parseISO } from 'date-fns';
export const groupData = (data) => {
  const transformedData = data.map((item) => {
    const originalDate = parseISO(item.start);
    const formattedDate = format(originalDate, 'yyyy-MM-dd');
    return { ...item, start: formattedDate };
  });

  const groupedData = {};
  transformedData.forEach((item) => {
    //const date = item.start.split('T')[0]; // Извлекаем дату без времени
    if (!groupedData[item.start]) {
      groupedData[item.start] = [];
    }
    groupedData[item.start].push(item);
  });
  return groupedData;
};

export const getQueueList = (data) => {
  const uniqueKeysMap = {};

  data.map((item) => {
    const keyParts = item.issue.key.split('-');
    const uniqueKey = keyParts[0];

    if (uniqueKeysMap[uniqueKey]) {
      uniqueKeysMap[uniqueKey].sumDuration += item.duration;
    } else {
      uniqueKeysMap[uniqueKey] = {
        key: uniqueKey,
        sumDuration: item.duration,
      };
    }
  });

  // Округляем значение sumDuration до двух знаков после запятой
  Object.values(uniqueKeysMap).forEach((value) => {
    if (Number.isInteger(value.sumDuration)) {
      value.sumDuration = value.sumDuration.toFixed(0);
    } else {
      const decimalPart = value.sumDuration.toString().split('.')[1];
      value.sumDuration =
        decimalPart.length === 1
          ? value.sumDuration.toFixed(1)
          : value.sumDuration.toFixed(2);
    }
  });

  return Object.values(uniqueKeysMap);
};
