let totalSum = 0;

export const createTable = (groupedData) => {
  //очищение таблицы
  const myTable = document.getElementById('myTable');

  if (myTable) {
    myTable.parentNode.removeChild(myTable);
  }

  const tableContainer = document.querySelector('.table-container');
  const table = document.createElement('table');
  table.id = 'myTable';
  const headerRow = table.insertRow();
  const headerDateCell = headerRow.insertCell(0);
  headerDateCell.classList.add('table_date_header');
  headerDateCell.textContent = 'Дата';
  const headerKeyCell = headerRow.insertCell(1);
  headerKeyCell.textContent = 'Задачи';
  headerKeyCell.classList.add('table_keys_header');
  const headerDurationCell = headerRow.insertCell(2);
  headerDurationCell.textContent = 'Длительность';

  Object.keys(groupedData).forEach((date) => {
    const row = table.insertRow();
    const dateCell = row.insertCell(0);
    dateCell.classList.add('table_date_header');
    const keysCell = row.insertCell(1);
    const durationsCell = row.insertCell(2);

    const keys = groupedData[date]
      .map((item) => {
        let formatedDuration = Math.round(item.duration * 10) / 10;
        if (Number.isInteger(item.duration)) {
          formatedDuration = item.duration.toFixed(0);
        } else {
          const decimalPart = item.duration.toString().split('.')[1];
          formatedDuration =
            decimalPart.length === 1
              ? item.duration.toFixed(1)
              : item.duration.toFixed(2);
        }

        let result = `<a href="https://tracker.yandex.ru/${item.issue.key}">${item.issue.key}</a>`;
        result += ` ${item.issue.display}  (${formatedDuration}ч) `;

        if (item.comment) {
          result += `${item.comment}`;
        }
        return result;
      })
      .join('<br>');

    let durations = 0;
    durations = groupedData[date].map((item) => item.duration);
    let sum = 0;
    sum = durations.reduce((acc, val) => acc + val, 0);
    totalSum += sum;
    let formattedSum;

    if (Number.isInteger(sum)) {
      formattedSum = sum.toFixed(0);
    } else {
      const decimalPart = sum.toString().split('.')[1];
      formattedSum = decimalPart.length === 1 ? sum.toFixed(1) : sum.toFixed(2);
    }

    const issueDate = new Date(date);

    // Получение компонентов даты
    const day = issueDate.getDate().toString().padStart(2, '0');
    const month = (issueDate.getMonth() + 1).toString().padStart(2, '0'); // Месяцы начинаются с 0
    const year = issueDate.getFullYear();

    // Форматирование в нужный вид
    const formattedDate = `${day}.${month}.${year}`;
    keysCell.innerHTML = keys;
    durationsCell.textContent = `${formattedSum}ч`;
    dateCell.textContent = formattedDate;
  });

  let formattedTotalSum;

  if (Number.isInteger(totalSum)) {
    formattedTotalSum = totalSum.toFixed(0);
  } else {
    const decimalPart = totalSum.toString().split('.')[1];
    formattedTotalSum =
      decimalPart.length === 1 ? totalSum.toFixed(1) : totalSum.toFixed(2);
  }

  const totalRow = table.insertRow();

  const totalCell = totalRow.insertCell(0);
  totalCell.classList.add('table_date_header');
  totalCell.textContent = 'Всего';
  totalRow.insertCell(1).textContent = '';
  const totalSumCell = totalRow.insertCell(2);
  totalSumCell.textContent = `${formattedTotalSum}ч`;

  const checkboxTotalSum = document.querySelector('.total-sum');
  if (checkboxTotalSum) {
    checkboxTotalSum.textContent = `= ${formattedTotalSum}ч`;
  }

  // Вставка таблицы в документ
  tableContainer.appendChild(table);
  totalSum = 0;
};
