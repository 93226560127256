export const formatFromDate = (date) => {
  const fromDate = new Date(date);
  fromDate.setDate(date.getDate() - 1);
  fromDate.setHours(21, 0, 0, 0);

  const year = fromDate.getFullYear();
  const month = fromDate.getMonth() + 1;
  const day = fromDate.getDate();
  const hours = fromDate.getHours();
  const minutes = fromDate.getMinutes();
  const seconds = fromDate.getSeconds();
  let formattedDate = '';

  formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day
    .toString()
    .padStart(2, '0')}T${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}.00`;
  return formattedDate;
};

export const formatToDate = (date) => {
  const toDate = new Date(date);
  toDate.setHours(20, 59, 59, 999);
  const year = toDate.getFullYear();
  const month = toDate.getMonth() + 1;
  const day = toDate.getDate();
  const hours = toDate.getHours();
  const minutes = toDate.getMinutes();
  const seconds = toDate.getSeconds();
  let formattedDate = '';
  formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day
    .toString()
    .padStart(2, '0')}T${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}.999`;
  return formattedDate;
};
