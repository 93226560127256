import { ReactComponent as Logo } from '../../assets/logo.svg';
import { Button, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Authorization } from '../../../pages/authorization/Authorization';
import { useEffect, useState } from 'react';

function Header() {
  const [opened, { open, close }] = useDisclosure(false);
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    const openModal = JSON.parse(localStorage.getItem('openModal'));
    if (openModal) {
      open();
    }
  }, []);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('storedData')) || [];
    setAccounts(storedData);
  }, []);

  const handleModalClose = () => {
    close();
    localStorage.removeItem('openModal');
  };

  return (
    <header className="header">
      <div className="header__wrapper">
        <div className="header__left">
          <Logo />
        </div>

        <div className="header__account">
          <Button fullWidth size="s" onClick={open}>
            {`${accounts.length > 0 ? 'Аккаунты' : 'Войти'}`}
          </Button>
        </div>
      </div>
      <>
        <Modal
          opened={opened}
          onClose={handleModalClose}
          radius={0}
          title="Авторизация"
          size="lg"
          centered
          transitionProps={{ transition: 'fade', duration: 200 }}
        >
          <Authorization updateAccounts={setAccounts} />
        </Modal>
      </>
    </header>
  );
}

export default Header;
