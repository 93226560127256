import React from 'react';
import { createRouter } from './appRouter';
import { RouterProvider } from 'react-router-dom';
import { Suspense } from 'react';
import '@mantine/core/styles.css';
import './styles/styles.css';
import { MantineProvider } from '@mantine/core';
import '@mantine/dates/styles.css';

function App() {
  return (
    <MantineProvider>
      <Suspense fallback={<div></div>}>
        <RouterProvider router={createRouter()} />
      </Suspense>
    </MantineProvider>
  );
}

export default App;
