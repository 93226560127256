import React, { useState, useEffect } from 'react';
import { Button } from '@mantine/core';
import { IoIosRemoveCircleOutline } from 'react-icons/io';
import { Select } from '@mantine/core';

export const Authorization = ({ updateAccounts }) => {
  const [trackers, setTrackers] = useState([]);
  const [storedData, setStoredData] = useState([]);
  const [selectedTracker, setSelectedTracker] = useState('');

  useEffect(() => {
    fetch('/api/v1/trackers')
      .then((response) => response.json())
      .then((data) => setTrackers(data.trackers));
    const storedData = JSON.parse(localStorage.getItem('storedData')) || [];
    setStoredData(storedData);
    updateAccounts(storedData);
  }, []);

  useEffect(() => {
    updateAccounts(storedData);
  }, [storedData]);

  const navigateToAuthPage = () => {
    if (selectedTracker) {
      window.localStorage.setItem(
        'currentData',
        JSON.stringify(selectedTracker),
      );
    }

    window.YaAuthSuggest.init(
      {
        client_id: '1f0ab73d4bbe4e03b71ef8900175cfd6',
        response_type: 'token',
        redirect_uri: 'https://timer.onder.tech/token.html',
      },
      '--https://timer.onder.tech/--',
    )
      .then(({ handler }) => handler())
      .then(async (data) => {
        try {
          const response = await fetch(
            `https://login.yandex.ru/info?format=json&oauth_token=${data.access_token}`,
          );
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const loginData = await response.json();
          const login = loginData.login;
          const storedData =
            JSON.parse(window.localStorage.getItem('storedData')) || [];
          const isLoginExists = storedData.some((item) => item.login === login);
          const activeTracker = trackers.find(
            (item) => item.name === selectedTracker,
          );

          if (!isLoginExists) {
            storedData.push({
              token: data.access_token,
              login: login,
              idOrg: activeTracker ? activeTracker.id : null,
              name: activeTracker ? activeTracker.name : null,
            });
            window.localStorage.setItem(
              'storedData',
              JSON.stringify(storedData),
            );
            const modifiedStoredData = JSON.parse(
              window.localStorage.getItem('storedData'),
            );
            setStoredData(modifiedStoredData);
            updateAccounts(modifiedStoredData);
            window.localStorage.setItem('openModal', 'true');
            window.location.reload();
          }
        } catch {}
      })
      .catch((error) => console.log('Что-то пошло не так: ', error));
  };

  const removeAccount = (login) => {
    const updatedData = storedData.filter((item) => item.login !== login);
    setStoredData(updatedData);
    window.localStorage.setItem('storedData', JSON.stringify(updatedData));
  };

  const handleSelectChange = (e, login) => {
    const selectedValue = e;
    const updatedStoredData = storedData.map((item) => {
      if (item.login === login) {
        const selectedTracker = trackers.find(
          (item) => item.name === selectedValue,
        );
        return {
          ...item,
          idOrg: selectedTracker ? selectedTracker.id : null,
          name: selectedTracker ? selectedTracker.name : null,
        };
      }
      return item;
    });

    setStoredData(updatedStoredData);
    localStorage.setItem('storedData', JSON.stringify(updatedStoredData));
  };

  const handleTrackerChange = (event) => {
    setSelectedTracker(event);
  };

  return (
    <div>
      <div className="column">
        <h5>
          Авторизуйтесь в трекерах нескольких организаций и получайте сводные
          отчеты.
        </h5>
        <div className="wrapper-button">
          {storedData.length > 0 &&
            storedData.map((item, index) => (
              <div className="button-container" key={item.login}>
                <div className="select-wrapper">
                  <Select
                    value={item.name || ''}
                    placeholder="Организация"
                    onChange={(value) => handleSelectChange(value, item.login)}
                    data={trackers.map((tracker) => ({
                      value: tracker.name,
                      label: tracker.name,
                    }))}
                  />
                  <span className="item-login">{item.login}</span>
                </div>
                <button
                  className="delete-button"
                  onClick={() => removeAccount(item.login)}
                >
                  <IoIosRemoveCircleOutline size="38" color="red" />
                </button>
              </div>
            ))}
        </div>
        <div className="button-container button-container--last_row">
          <Select
            value={selectedTracker}
            placeholder="Организация"
            onChange={(value) => handleTrackerChange(value)}
            data={trackers.map((tracker) => ({
              value: tracker.name,
              label: tracker.name,
            }))}
          />
          <Button onClick={navigateToAuthPage}>Добавить аккаунт</Button>
        </div>
      </div>
    </div>
  );
};
