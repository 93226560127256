import { formatDuration } from './formatDuration';
import { groupData } from './groupData';
import { createTable } from './createTable';
import axios from 'axios';

export const fetchData = async (url, orgId, token) => {
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `OAuth ${token}`,
        'Content-Type': 'application/json',
        'X-Org-ID': orgId,
      },
      withCredentials: true,
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    console.error('Request failed:', error);
    throw error;
  }
};
export const fillTable = (data, filterOptions, checkbox) => {
  data.map((item) => {
    const hours = formatDuration(item.duration);
    item.duration = hours;
  });

  const groupedData = groupData(data);

  if (filterOptions.length > 0 && checkbox) {
    const obj = Object.fromEntries(
      Object.entries(groupedData).map(([key, value]) => [
        key,
        value.filter((item) =>
          filterOptions.includes(item.issue.key.split('-')[0]),
        ),
      ]),
    );
    const filteredObj = Object.fromEntries(
      Object.entries(obj).filter(([key, value]) => value.length > 0),
    );
    const sortedObject = Object.keys(filteredObj)
      .sort((a, b) => new Date(a) - new Date(b))
      .reduce((obj, key) => {
        obj[key] = filteredObj[key];
        return obj;
      }, {});

    createTable(sortedObject);
  } else if (!checkbox) {
    const sortedObject = Object.keys(groupedData)
      .sort((a, b) => new Date(a) - new Date(b))
      .reduce((obj, key) => {
        obj[key] = groupedData[key];
        return obj;
      }, {});

    createTable(sortedObject);
  } else {
    const data = {};
    createTable(data);
  }
};
